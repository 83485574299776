import ImageUploader from "react-images-upload";
import { CircularProgress, Button } from "@material-ui/core";
import './App.css';
import { useState, useRef } from "react";
import { drawRect } from "./utilities";
import axios from 'axios';
import ReactJson from 'react-json-view';
import { firebaseApp } from './firebaseConfig';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';

const App = props => {
  const [pic, setPic] = useState([]);
  const [picName, setPicName] = useState(null);
  const [canvasContext, setCanvasContext] = useState();
  const [inferencing, setInferencing] = useState(false);
  const [prediction, setPrediction] = useState({});
  const [dimension, setDimension] = useState([0, 0]);
  const [token, setToken] = useState();
  const [userSignedIn, setUserSignedIn] = useState(false);
  const imageRef = useRef();
  const canvasRef = useRef();
  const endpoint = 'https://us-central1-yolomoves-fb435.cloudfunctions.net/app';
  //const endpoint = 'http://localhost:5000/yolomoves-fb435/us-central1/app';
  //const endpoint = 'https://us-central1-yolouploader.cloudfunctions.net/app';

  const onDrop = async(picture) => {
    const url = URL.createObjectURL(picture[0]);
    setPic(url);
    setPicName(picture[0].name);
    setPrediction({});

    let img = new Image();
    img.src = url;
    img.onload = () => {
      setDimension([img.width, img.height]);
    }
    await img.onload();

    if (typeof(canvasContext) != "undefined") {
      canvasContext.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  const clickPredict = async (ev) => {
    ev.preventDefault();
    setInferencing(true);
    
    // convert to base64
    const canvas = document.createElement('canvas');
    canvas.width = dimension[0];
    canvas.height = dimension[1]
    const _ctx = canvas.getContext('2d');
    _ctx.drawImage(imageRef.current, 0, 0, dimension[0], dimension[1]);
    let dataURL = canvas.toDataURL('image/jpeg');

    dataURL = JSON.stringify({
      'photo': dataURL
    });

    const response = await axios.post(
      endpoint, 
      dataURL,
      {
        headers: { 
            'Content-Type' : 'application/json' ,
            'Authorization' : token,
            'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': true
        }
      }
    )
    console.log(response);
    canvasRef.current.width = imageRef.current.clientWidth;
    canvasRef.current.height = imageRef.current.clientHeight;
    const ctx = canvasRef.current.getContext("2d");
    console.log(response);
    setPrediction(response.data.length === 0 ? {'data': 'No prediction found'} : response.data);
    drawRect(response.data, ctx);
    setCanvasContext(ctx);
    setInferencing(false);
  }

  const Authenticate = async () => {
    var provider = new GoogleAuthProvider();
    let result = await signInWithPopup(firebaseApp, provider);
    let token = result.user.accessToken;
    setToken('Bearer '+token);
  }

  onAuthStateChanged(firebaseApp, (user) => {
    if (user) {
      setToken('Bearer '+user.accessToken);
      setUserSignedIn(true);
    } else {
      setUserSignedIn(false);
    }
  })

  if (!userSignedIn) {
    return (
      <button onClick={Authenticate}>Authenticate with Google</button>
    )
  } else {
    return (
      <div>
        <ImageUploader
          withPreview={false}
          buttonText={(typeof pic==='undefined' || pic.length===0) ? 'Choose Image' : picName + ' chosen, click to change'}
          withIcon={true}
          onChange={onDrop}
          imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
          maxFileSize={5242880}
          singleImage={true}
        />

        {typeof pic==='undefined' || pic.length!==0 ? 
          <img 
            src={pic} alt="upload-preview" 
            ref={imageRef}
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              zindex: 9,
              width: dimension[0],
              height: dimension[1],
            }}/> 
          : ''
        }

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 8,
            width: dimension[0],
            height: dimension[1],
          }}
        />
        
        {
          inferencing ?
          <CircularProgress /> :
          <Button disabled={typeof pic==='undefined' || pic.length===0} onClick={clickPredict} color="secondary" variant="contained" component="span" type="submit">Predict</Button>
        }

        <div>
          {
            (typeof(prediction) === null) ?
            <h3></h3> :
            <ReactJson src={prediction} />
          }
        </div>
      </div>
    );
  }
}

export default App;
