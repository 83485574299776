import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const config = {

  apiKey: "AIzaSyDTgemNNQj_PegqRMp60hyOE0s07dPAoi0",

  authDomain: "yolomoves-fb435.firebaseapp.com",

  databaseURL: "https://yolomoves-fb435-default-rtdb.asia-southeast1.firebasedatabase.app",

  projectId: "yolomoves-fb435",

  storageBucket: "yolomoves-fb435.appspot.com",

  messagingSenderId: "143752816307",

  appId: "1:143752816307:web:0a3e434767f25c6a8572c6",

  measurementId: "G-QRWKY2SLX4"

};


const app = initializeApp(config);
const analytics = getAnalytics(app);

export const firebaseApp = getAuth(app);
